
import {
  ref,
  computed,
  defineComponent,
} from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { Actions } from '@/store/enums/store.enums';
import FormPage from '@/view/pages/common/users/FormPage.vue';
import ApiService from '@/core/services/ApiService';
import BasePre from '@/core/components/base/BasePre.vue';
import { ElMessage } from 'element-plus';
import isEmptyObject from '@/core/helpers/isEmptyObject';
import doesObjectsEqual from '@/core/helpers/doesObjectsEqual';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'users-edit',
  components: {
    FormPage,
    BasePre,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * The entry data object.
     */
    const entryData = ref({});
    const entryDataForComparing = ref({});
    const formRefParent = ref(null);
    const buttonsLoading = ref(false);

    /**
     * Form changes comparing.
     */
    const formsAreEqual = computed(() => {
      return doesObjectsEqual(entryData.value, entryDataForComparing.value);
    });

    /*********************
    * UPLOAD START
    *********************/


    /**
     * Interface of the image object.
     */
    interface InterfaceObjectPhotos {
      id: number
      url: string
      name: string
      path: string
      original_name: string
      new?: boolean
      raw?: object
    }

    const saveButtonClick = () => {
      formRefParent.value.saveForm();
    };

    /*********************
    * UPLOAD END
    *********************/



    /*********************
    * FORM ACTIONS START
    *********************/

    /**
     * Form validation.
     * Calling the child method
     * and get the result of the validation.
     * @returns {boolean}.
     */
    const validateForm = () => {
      const result = formRefParent.value.validateForm()
      return result
    }


    /**
     * Request to save the entry data object.
     * @param {object} Entry data object.
     * @returns {boolean}.
     */
    const requestSaveForm = async data => {
      // console.log('> run requestSaveForm')

      ApiService.setHeader()

      return await ApiService.post(`/admin/users`, data)
        .then(response => {
          console.log('response ::', response.data);
          return response.data;
        })
          .catch(error => {
            if (error.response.status === 422) {
              let offset = 50;
              for (const key in error.response.data.errors) {
                // console.log('error key', key);
                ElMessage({
                  offset: offset,
                  type: 'warning',
                  message: error.response.data.errors[key][0],
                });
                offset += 35;
              }
            } else {
              ElMessage({
                offset: 50,
                type: 'warning',
                message: 'Ошибка сохранения',
              });
            }
            return false;
          });
    }


    /**
     * Requesting to the server
     * for images uploading.
     * @param {object} Image file
     * @returns {object|null}.
     */
    const requestPhotoToServer = async file => {
      const photoId = await store.dispatch(
        Actions.UPLOAD_PHOTO,
        file
      )
      console.log('> photoId', photoId);
      return photoId;
    };

    /**
     * Sending photo to server.
     * @param {object} The entryData object
     * @returns {number}.
     */
    const sendPhotoToServer = async photo => {
      const photoId = await requestPhotoToServer(photo);

      if (photoId) {
        return photoId;
      }
      return null;
    };

    /**
     * Entry saving.
     * Validation checking.
     * Preparation of the sending object.
     * Calling the server requesting function.
     */
    const saveForm = async (flag, data) => {
      buttonsLoading.value = true;
      if (flag) {
        // console.log('> saveForm >> data', data);
        if (data.photo && data.photo[0] && data.photo[0].raw) {
          // console.log('> if >> photo');
          data.photo = await sendPhotoToServer(data.photo[0]);
        } else if (data.photo && data.photo[0] && data.photo[0].id) {
          // console.log('> else if >> photo');
          data.photo = data.photo[0].id;
        } else {
          delete data.photo;
          // console.log('> else >> photo');
        }
        const resultRequestSaveForm = await requestSaveForm(data);

        if (resultRequestSaveForm['result']) {
          ElMessage({
            offset: 50,
            type: 'success',
            message: 'Пользователь сохранён',
          });

          router.push({
            name: 'users-edit',
            params: {
              id: resultRequestSaveForm['data'].id,
            },
          });
        }
        buttonsLoading.value = false;
      } else {
        buttonsLoading.value = false;
      }
    };



    /*********************
    * FORM ACTIONS END
    *********************/


    return {
      route,
      isEmptyObject,
      // FORM
      entryData,
      formRefParent,
      saveForm,
      // FORM BUTTONS
      formsAreEqual,
      buttonsLoading,
      saveButtonClick,
    }
  },
})
